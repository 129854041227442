import { ItemDetailTabContent } from "./tabContent/ItemDetailTabContent.js";
import { ItemService } from "../common/api.service.js";
import { VideoLibrary } from '../videoLibrary/VideoLibrary.js'

let ItemDetailsModal = {
  data: function () {
    return {
      // Video background button
      isShowBackgroundVideoButton: this.isVideoAdUser == 'True' ? true : false,
      assetGroup: null, // = itemAsset
      isVideoSaved: false, // Control video saved message show / hide
      itemAssetGroup: null,
      videoAssetGroup: null,
      itemObj: this.item,
      itemThumbnailUrl: "",
      isItemThumbnailUrlLoading: false,
      isItemThumbnailUrlLoaded: false
    };
  },
  props: {
    modalKey: String,
    item: Object,
    item_type: String,
    websiteName: String,
    website: String,
    isVideoAdUser: String,
    websiteId: String,
    presetAssetThumbnail: String,
    presetImageThumbnail: String
  },
  components: {
    'item-detail-tab-content': ItemDetailTabContent,
    'video-library': VideoLibrary
  },
  mounted: function () {
    if (this.isShowBackgroundVideoButton) this.handleItemAssetGroup()
  },
  computed: {
    componentProps: function () {
      return {
        website: this.website,
        website_id: this.websiteId,
        preset_asset_thumbnail: this.presetAssetThumbnail,
        itemAssetGroup: this.assetGroup,
        isAdView: false
      }
    },
    // To fix the modal opened twice as same item have two identical id's detail modal
    // Add modalKey from parent and update the button's v-b-modal that open the modal
    modalId: function () {
      const itemId = this.itemObj.id ? this.itemObj.id : this.itemObj.item_id
      return itemId + this.modalKey
    }
  },
  watch: {
    videoAssetGroup: function (newVal, oldVal) {
      if (!newVal) return
      let newValAssetGroup = newVal.id
      // Check if the updated asset group is the same with item asset group (from db record), 
      // if yes, will not trigger update
      if (newValAssetGroup !== this.assetGroup?.id) {
        this.updateItemVideoBackground()
      }
    },
  },
  methods: {
    onModalShow() {
      // Get the item when modal open to prevent data out of sync with db record
      this.getItemObject();
    },
    getItemObject: async function () {
      let itemId = this.itemObj.item_id ? this.itemObj.item_id : this.itemObj.id
      if (!itemId) return
      const params = {
        item_ids : itemId,
        image_size: "LARGE"
      }
      return ItemService.init(this.website).query(params).then(response => {
        this.itemObj = response.results[0]
        if (response.asset_group_detail) {
          this.assetGroup = {
            name: response.asset_group_detail.name,
            id: response.asset_group,
            asset_count: response.asset_group_detail.assets.length,
            assets: response.asset_group_detail.assets
          }
          this.itemAssetGroup = response.asset_group
        }
        this.loadItemThumbnail();
      });
    },
    loadItemThumbnail() {
      if (!this.itemObj?.thumbnail_urls?.length) return
      this.isItemThumbnailUrlLoaded = false
      this.isItemThumbnailUrlLoading = true
      this.itemThumbnailUrl = this.itemObj.thumbnail_urls[0]
    },
    onItemThumbnailLoaded() {
      this.isItemThumbnailUrlLoaded = true
      this.isItemThumbnailUrlLoading = false
    },
    replaceItemThumbnailByDefault() {
      this.itemThumbnailUrl = this.presetImageThumbnail
    },
    handleItemAssetGroup() {
      if(!this.itemObj.id) return
      if (this.itemObj.asset_group_detail) {
        this.assetGroup = {
          name: this.itemObj.asset_group_detail.name,
          id: this.itemObj.asset_group,
          asset_count: this.itemObj.asset_group_detail.assets.length,
          assets: this.itemObj.asset_group_detail.assets
        }
        this.itemAssetGroup = this.itemObj.asset_group
      }
    },
    updateItemVideoBackground() {
      // Prevent modal from closing
      // bvModalEvt.preventDefault();
      let params = {
        "asset_group": this.videoAssetGroup.id
      }
      // Update item with asset group
      ItemService.init(this.website).update(this.itemObj.id, params).then(response => {
        this.isVideoSaved = true;
        // Hide video saved message after 4 secs
        setTimeout(function () {
          this.isVideoSaved = !this.isVideoSaved;
        }.bind(this), 4000);
      });
    },
    // For store to update the videoAssetGroup (selected asset inside VideoLibraryManagementTab, aka this.store.assetGroups)
    updateVideoAssetGroup(assetGroup) {
      this.videoAssetGroup = assetGroup
    },
    getItemDetailPageUrl() {
      let baseUrl = window.location.origin;
      return baseUrl + '/' + this.website + '/items/' + this.itemObj.id
    },
    changeStatus(newValue) {
      let message = newValue ? "Are you sure you want to exclude this item from the inventory?"
        : "Are you sure you want to activate this item in the inventory?";

      if (confirm(message)) {
        ItemService.init(this.website).update(this.itemObj.id, { 'excluded': newValue }).then(response => {
          // Close modal and refresh background
          this.$emit('onUpdateItemVisibility')
          this.$nextTick(() => {
            // Use hide event to keep fade out visual
            this.$bvModal.hide(this.itemObj.id ? this.itemObj.id : this.itemObj.item_id)
          })
        });
      }else{
        // To keep `excluded`'s value remain the same
        this.itemObj.excluded = !this.itemObj.excluded
        return
      }
    }
  },
  template: `
        <b-modal
        :id="modalId"
        size="xl"
        content-class="default-theme-border-radius"
        body-class="item-details-modal-body px-4 pt-2 overflow-auto eggplant-color"
        header-class="border-0"
        hide-footer
        @show="onModalShow">
            <template #modal-title>
              <p class="m-0 font-weight-600 ont-size-md cedar-color">Item Details</p>
              <h4 class="m-0 eggplant-color font-weight-600">{{ itemObj.name }}</h4>
            </template>
            <div v-if="!itemObj.id" class="container"></div>
            <div v-else class="container">
                <div class="row">
                  <div class="col pl-0">
                    <div class="container">
                      <div class="row">
                          <div class="col pl-0 item-thumbnail-container">
                            <div :class="{'hide': !isItemThumbnailUrlLoading}" class="loading w-100 h-100 position-absolute"></div>
                            <img :class="isItemThumbnailUrlLoaded ? 'show' : 'hide'"  class="w-100 rounded position-relative" v-bind:src="itemThumbnailUrl" @load="onItemThumbnailLoaded" @error="replaceItemThumbnailByDefault" ref="itemThumbnail">
                          </div>
                      </div>
                      <div class="row">
                          <div class="col pl-0">
                            <component
                                v-if="isShowBackgroundVideoButton"
                                class="video-library pt-2 px-3"
                                is="video-library"
                                v-bind="{...componentProps}"
                                v-on:updateParentVideoAssetGroup="updateVideoAssetGroup"
                                />
                            <div :class="isVideoSaved ? 'show' : 'hide'" class="background_video_saved_message bg-success rounded py-2 my-2 pl-3 w-100 fade">
                                <font-awesome-icon class="text-white" :icon="['fas', 'check-circle']" />
                                <span class="text-white">Video saved!</span>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                    <div class="col pr-0">
                      <b-tabs 
                      content-class="mt-3"
                      nav-class="eggplant-color hello"
                      active-nav-item-class="font-weight-bold eggplant-color">
                        <b-tab title="Item" active>
                          <item-detail-tab-content :item="itemObj" :item_type="item_type"></item-detail-tab-content> 
                        </b-tab>
                        <b-tab title="Data Source">
                          <div class="row">
                            <p class="col font-weight-bold">ID:</p>
                            <p class="col">{{itemObj.external_id}}</p>
                          </div>
                          <div v-for="(value, key) in itemObj.datasource_details" class="row">
                            <p class="col font-weight-bold">{{ key }}:</p>
                            <p class="col">{{ value }}</p>
                          </div>
                          <div class="row">
                            <p class="col font-weight-bold">Item visibility:</p>
                            <div class="col">
                              <div class="switch-label d-inline text-success mr-2">
                                  <span :class="{'font-weight-bold': itemObj.excluded ? false : true }" v-b-popover.hover.top="'This item will appear in items, sets and ads.'">Visible</span>
                              </div>
                              <b-form-checkbox v-model="itemObj.excluded" name="check-button"  switch class="item-status-switch d-inline" size="lg" @change.native="changeStatus(itemObj.excluded)" :class="{'checked': itemObj.excluded ? true : false }" key="itemObj.id"></b-form-checkbox>
                              <div class="switch-label d-inline text-danger">
                                <span :class="{'font-weight-bold': itemObj.excluded ? true : false }" v-b-popover.hover.top="'This item has been manually excluded and will not appear in items, sets, or ads.'">Exclude</span>
                              </div>
                            </div>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </div>
                </div>
            </div>                  
        </b-modal>
`
};

export { ItemDetailsModal };
