import { AdThemeCardList } from './AdThemeCardList.js';
import { DHAdThemeCardList } from './DHAdThemeCardList.js';
import { usePermissionsStore } from "../store/PermissionStore";
import { mapState } from "pinia";
import { TierUpgradeButton } from "../tiers/TierUpgradeButton.js"
import { WebsitesService } from "../common/api.service.js";

let AdThemeModal = {
    data() {
        return {
            permissionsStore : usePermissionsStore(),
            highlightedTheme: null,
            themeCustomFields: {},
            isModalShowing: false, // Fix modal flickering due to iframe display on modal show
            isShowUploadThemeFooter: false,
            showConfirmApplyThemeModal: false,
            modalKey: "CustomThemesCreation",
            adThemeModalId: "modal-themes",
        }
    },
    props: {
        themeProps: Object,
        store: Object,
        staticUrl: String,
        accountId: String,
        disabled: Boolean,
        videoBackend: String
    },
    components: {
        AdThemeCardList,
        DHAdThemeCardList,
        TierUpgradeButton
    },
    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            if(modalId === this.adThemeModalId){
                this.refreshHighlightedTheme()
                this.isModalShowing = true
            }
        })
        
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if(modalId === this.adThemeModalId){
                this.isModalShowing = false
            } 
        })
    },
    methods: {
        refreshHighlightedTheme() {
            this.highlightedTheme = this.store.ad.theme_config?.theme_version || this.store.ad.templates[0];
            this.themeCustomFields = {};
        },
        highlightTheme(theme, customFields) {
            this.highlightedTheme = theme;
            this.themeCustomFields = customFields;
        },
        select() {
            if(!this.isDHBackend) {
                this.confirmSelectedTheme()
            }else{
                this.showConfirm()
            }
        },
        applyTheme(){
            this.store.updateState('isLoading', {
                status: true,
                message: this.store.loadingMessages.applyingTheme,
                isShowSpinner: true
            });
            this.confirmSelectedTheme()
        },
        confirmSelectedTheme(){
            if(!this.isDHBackend) this.store.updateCustomFields(this.themeCustomFields);
            this.store.updateParentTheme(this.highlightedTheme);
            this.$bvModal.hide(this.adThemeModalId);
            this.showConfirmApplyThemeModal =  false
            // Update advertiser last selection
            this.updateWebsiteLastSelectedThemeName()
        },
        updateWebsiteLastSelectedThemeName() {
            const websiteUpdates = {
                "last_selected_video_template": this.highlightedTheme.templateGroup
            };

            if (this.highlightedTheme.templateGroup !== this.store.lastSelectedTemplate) {
                WebsitesService.update(`${this.themeProps.website}/`, websiteUpdates)
                    .catch((error) => {
                        console.error("Error updating last selected theme:", error);
                    })
            } else {
                console.log("There is no need to update the last selected theme, as it remains unchanged from before.");
            }
        },
        showConfirm() {
            this.showConfirmApplyThemeModal =  true
        },
        cancel() {
            this.$bvModal.hide(this.adThemeModalId);
        },
        // To display custom theme footer
        // tabIndex ref to tabs in AdThemeCardList
        updateModalFooter(tabIndex) {
            if(tabIndex === 1) { // custom tab activated
                this.isShowUploadThemeFooter = true
            }else{
                this.isShowUploadThemeFooter = false
            }
        },
        onShow(bvModalEvt) {
            // Allow disabling the modal
            if (this.disabled) {
                bvModalEvt.preventDefault()
            }
        },
        generateTemplatePreviews() {
            this.$emit('generateTemplatePreviews')
        }
    },
    computed: {
        ...mapState(usePermissionsStore, ['permissions']),
        isDHBackend() {
            return this.videoBackend === 'design_huddle' 
        }
    },
    template: `
    <div>
        <b-modal
            v-model="showConfirmApplyThemeModal"
            title="Are you sure?"
            hide-footer>
            <p>All changes will be lost upon applying the theme. Your current modifications will not be saved.</p>
            <div class="text-right">
                <b-button variant="danger" @click="applyTheme"
                    >Yes, apply</b-button
                >
                <b-button
                    variant="primary"
                    @click="showConfirmApplyThemeModal = false"
                    >Hold On</b-button
                >
            </div>
        </b-modal>
        <b-modal
        :id="adThemeModalId"
        size="xl"
        scrollable
        title="Select a Theme"
        @show="onShow">
            <template #modal-title>
                <h4 class="m-0 eggplant-color font-weight-600">Select a Theme</h4>
            </template>
            <AdThemeCardList v-if="isModalShowing && !isDHBackend" v-bind="{ ...themeProps }" :staticUrl="staticUrl" :highlightTheme="highlightTheme" :highlightedTheme="highlightedTheme" :store="store" @updateModalFooter="updateModalFooter" :accountId="accountId"/>
            <DHAdThemeCardList v-if="isModalShowing && isDHBackend" v-bind="{ ...themeProps }" :staticUrl="staticUrl" :highlightTheme="highlightTheme" :highlightedTheme="highlightedTheme" :store="store" @updateModalFooter="updateModalFooter" :accountId="accountId" @generateTemplatePreviews="generateTemplatePreviews"/>

            <template #modal-footer>
                <div class="w-100">
                    <div v-if="highlightedTheme" class="align-items-center d-flex justify-content-between m-2">
                        <div class="align-items-center d-flex w-75"></div>
                        <div class="w-25">   
                            <TierUpgradeButton
                            v-if="isShowUploadThemeFooter"
                            :isShowDefault="permissions.tiered_themeversion_add_temporary"
                            class="custom-theme-select-btn float-right m-1"
                            @defaultFunction="select"
                            :buttonText="permissions.tiered_themeversion_add_temporary ? 'Select' : 'Upgrade'"
                            :modalKey="modalKey"
                            :staticUrl="staticUrl"
                            :isShowShadow="false"
                            :accountId="accountId"
                            />
                            <b-button v-else variant="primary" class="library-theme-select-btn float-right m-1"
                            @click=select>
                                Select
                            </b-button>
                            <b-button v-if="!isShowUploadThemeFooter || permissions.tiered_themeversion_add_temporary" variant="secondary" class="float-right m-1"
                            @click=cancel>
                                Cancel
                            </b-button>
                        </div>
                    </div>
                    <div v-else class="justify-content-end">
                        <b-button variant="secondary" class="float-right m-1"
                        @click="$bvModal.hide(adThemeModalId)"
                        >
                            Cancel
                        </b-button>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
`
}

export {AdThemeModal};
