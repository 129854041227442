import Vue from 'vue';
import {AssetsService} from "../common/api.service.js";
import {LogoCard} from "./LogoCard.js";
import VueFormulate from "@braid/vue-formulate";

Vue.use(VueFormulate);

let LogoCardList = {
    data: function () {
        return ({
            logos: [],
            activeTabVal: null,
        })
    },
    components: {
        'logo-card': LogoCard
    },
    computed: {
        activeTab: {
            get() {
                return this.activeTabVal
            },
            set(newValue) {
                this.activeTabVal = newValue
                return newValue
            }
        },
        isEmptyLogos: function() {
          return this.logos.length == 0;
        },
    },
    watch: {
        isEmptyLogos: function(newValue) {
            this.activeTabVal = newValue ? 0 : 1;
        }
    },
    methods: {
        getLogos() {
            AssetsService.init(this.website).query(
                {
                    only: this.store.logoDetailFields,
                    ordering: '-default,name',
                    type: 'logo'
                }
            ).then(response => {
                this.logos = response.results;
                this.formatLogos()
            });
        },
        formatLogos() {
            // Move the selected logo to index 1
            // If the selected is a default logo then leave it at index 0
            if (this.highlightedLogo) {
                 let ind = this.logos.findIndex((ele) => {
                    return ele.id === this.highlightedLogo.id
                })
                if (ind > 0) {
                    this.logos.splice(1, 0, this.logos.splice(ind, 1)[0])
                }
            }
        },
        async uploadFile(file, progress, error, options) {
            let formData = new FormData();
            formData.append('name', file.name);
            formData.append('asset', file);
            formData.append('type', 'logo');
            formData.append('website', this.store.state.websiteId);
        
            AssetsService.init(this.website).create(
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
            ).then(response => {
                this.onClickedItem(response.data)
                this.getLogos();
                this.activeTab = 1 // move to the library tab
                console.log('Uploaded file:', response);
            }).catch(err => {
                console.log(err)
            })
        },
        updateDefault(logo) {
            AssetsService.init(this.website).update(
                logo.id,
                {
                    default: true,
                    website: logo.website
                }).then(() => {
                    // Find the default logo and the new default logo and update their values accordingly
                    let oldDefaultIndex = this.logos.findIndex((ele) => {return ele.default == true})
                    let newDefaultIndex = this.logos.findIndex((ele) => {return ele.id == logo.id;})
                    this.logos[oldDefaultIndex].default = false;
                    this.logos[newDefaultIndex].default = true;
                });
        },
        onClickedItem(logo) {
            this.highlightLogo(logo);
        },
    },
    mounted: function () {
        this.getLogos()
    },
    props: {
        website: String,
        preset_image_thumbnail: String,
        highlightedLogo: Object,
        highlightLogo: Function,
        store: Object
    },
    template:
    `
                <div>
                  <b-tabs v-model="activeTab" content-class="mt-3">
                    <b-tab title="Upload logo">
                         <div class="upload-logo">
                           <FormulateInput
                                type="image"
                                name="logo"
                                help="Select a png, jpg to upload."
                                :uploader="uploadFile"
                                validation="mime:image/jpeg,image/png"
                                class="custom-file-upload upload-input-size"
                                />
                       </div>
                    </b-tab>
                    <b-tab title="Logo Library">
                     <div v-if="isEmptyLogos" class="upload-logo flex-column">
                         <p class="m-0">You haven't added any logos.</p>
                         <a href="#" @click="activeTab = 0" class="text-center">Upload one now</a>
                     </div>
                     <div v-else class="row ml-2">
                         <logo-card
                         v-for="logo in logos"
                         :logo="logo"
                         :key="logo.id"
                         :preset_image_thumbnail="preset_image_thumbnail"
                         :currentSelection="highlightedLogo ? highlightedLogo.id : null"
                         @updateDefault="updateDefault"
                         @updateSelection="onClickedItem"
                         />
                     </div>
                    </b-tab>
                  </b-tabs>
                </div>
    `,
}

export {LogoCardList}
