// Components
import { AdThemeCard } from "./AdThemeCard.js";

// Utility functions
import { isEmpty } from "lodash";

// Pinia store and utilities
import { usePermissionsStore } from "../store/PermissionStore.js";
import { useDHVideoAdEditStore } from "../store/DHVideoAdEditStore.js";
import { mapState, storeToRefs } from "pinia";

/**
 * DHAdThemeCardList Component
 * 
 * Displays a list of theme cards for ad creation and editing.
 * Manages theme selection, including standard and custom themes.
 * Integrates with Design Huddle for generate variable template previews.
 *
 * @see AdThemeCard component used for individual theme rendering
 */
const DHAdThemeCardList = {
    name: "DHAdThemeCardList",
    data: function () {
        return ({
            permissionsStore: usePermissionsStore(),
            themes: null,
            customThemes: null,
            ads: null,
            selectedAd: null,
            ad: "",
            hover: false,
            activeTab: 0, // Default active the first tab
            dhVideoStore: storeToRefs(useDHVideoAdEditStore())
        })
    },
    props: {
        isVideo: Boolean,
        website: String,
        preset_image_thumbnail: String,
        highlightedTheme: Object,
        store: Object,
        highlightTheme: Function,
        staticUrl: String
    },
    computed: {
        ...mapState(usePermissionsStore, ['permissions']),
        tierUpgradeDogImageUrl: function () {
            return this.activeTab === 1 ? this.staticUrl + 'img/doge_plus_eggplant.png' : this.staticUrl + 'img/doge_plus.png';
        },
        isCustomThemesEmpty() {
            return isEmpty(this.dhVideoStore.getGroupedCustomTemplateLists)
        },
    },
    methods: {
        // We currently only have the `theme` type available here, in contrast to the AdThemeCardList, where we have the option to 'Copy from Ad'.
        getThemeAndCustomFieldsFromEvent(obj) {
            return [obj];
        },
        onClickedItem(type, obj) {
            this.highlightTheme(...this.getThemeAndCustomFieldsFromEvent(obj));
        },
    },
    watch: {
        activeTab(newValue) {
            this.$emit('updateModalFooter', newValue)
        },
        'dhVideoStore.ad.itemList'(newValue) {
            if(newValue.length) this.$emit('generateTemplatePreviews')
        },
    },
    components: {
        AdThemeCard
    },
    template:
        `
    <div>
        <keep-alive>
          <b-tabs v-model="activeTab">
            <b-tab title="Standard" class="mt-3">
                <keep-alive>
                    <div v-if="dhVideoStore.getGroupedTemplateLists === null" class="m-5 text-center"><b-spinner></b-spinner></div>
                    <div v-else class="row ml-4">
                        <AdThemeCard
                        v-for="theme in dhVideoStore.getGroupedTemplateLists"
                        :theme="Array.isArray(theme) ? theme[0] : theme"
                        :key="theme.template_id"
                        :preset_image_thumbnail="preset_image_thumbnail"
                        :currentSelection="highlightedTheme ? highlightedTheme.id : null"
                        v-on:update="onClickedItem"
                        />
                    </div>
                </keep-alive>
            </b-tab>
            <b-tab :active="highlightedTheme?.is_custom" class="mt-3">
                <template #title>
                    Custom
                    <img v-if="!permissions.tiered_themeversion_add_temporary" class="align-top" :src="tierUpgradeDogImageUrl">
                </template>
                <keep-alive>
                    <div v-if="dhVideoStore.getGroupedCustomTemplateLists === null" class="m-5 text-center"><b-spinner></b-spinner></div>
                    <div v-else class="row ml-4">
                        <div v-if="isCustomThemesEmpty && permissions.tiered_themeversion_add_temporary">No custom themes</div>
                        <div v-else-if="isCustomThemesEmpty && !permissions.tiered_themeversion_add_temporary" class="d-flex align-items-baseline">
                            <p class="mr-4">Looks like you’ve discovered custom themes. Upgrade now to upload custom themes!</p>
                        </div>
                        <AdThemeCard
                        v-else
                        v-for="theme in dhVideoStore.getGroupedCustomTemplateLists"
                        :theme="Array.isArray(theme) ? theme[0] : theme"
                        :key="theme.template_id"
                        :preset_image_thumbnail="preset_image_thumbnail"
                        :currentSelection="highlightedTheme ? highlightedTheme.id : null"
                        v-on:update="onClickedItem"
                        />
                    </div>
                </keep-alive>
            </b-tab>
          </b-tabs>
        </keep-alive>
    </div>
    `,
}

export { DHAdThemeCardList }
