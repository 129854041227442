// Components
import { LogoModal } from "./logos/LogoModal.js";

// Utility functions
import { debounce, isEmpty } from "lodash";

// Pinia store and utilities
import { useDHVideoAdEditStore } from "./store/DHVideoAdEditStore.js";
import { storeToRefs } from 'pinia';

import { DesignHuddleService } from './common/api.service.js';

/**
 * DHVideoAdEditor Component
 * 
 * Implements the core Design Huddle editor functionality.
 * Handles template manipulation and rendering within the Design Huddle framework.
 * Manages project creation, loading, and updating based on user interactions.
 *
 */
const DHVideoAdEditor = {
    name: "DHVideoAdEditor",
    data: function () {
        return {
            dhEditor: null,
            store: storeToRefs(useDHVideoAdEditStore()),
            editorContainerId : "dh-container",
        }
    },
    props: {
        website: String,
        preset_image_thumbnail: String
    },
    computed: {
    },
    methods: {
        createProjects() {
            this.store.updateState('isLoading', { status: true, message: this.store.loadingMessages.creatingProject, isShowSpinner: true });

            // Clear ad.projects in store before create new projects
            if(this.store.ad.projects.length) this.store.clearAdProjects()

            const selectedTemplates = this.store.ad.templates // Contain all sizes
            if(!selectedTemplates || !selectedTemplates.length) return
        
            const projectData = [];
            const additionalData = {};
            selectedTemplates.forEach(template => {
                const templateId = template.template_id;
                const mergedCustomizations = {
                    classes: {
                        ...this.store.toTCO({
                            image: {
                                propertyName: 'url',
                                value: this.store.getCurrentItem?.thumbnail_urls[0]
                            },
                            ...this.store.itemElementValues,
                            ...this.store.paletteElementValues
                        })
                    }
                };

                projectData.push({
                    template_id: templateId,
                    customizations: mergedCustomizations
                });
                additionalData[templateId] = {
                    dimensions: template.dimensions,
                    duration: template.duration
                };
            });

            DesignHuddleService.createProjects(projectData).then((response) => {
                response.data.projects.forEach((project) => {
                    const templateId = project.template_id;
                    this.store.addAdProject(project);

                    // Update
                    if (additionalData[templateId]) {
                        this.store.updateProjectData(project.project_id, additionalData[templateId]);
                    };
                });
                this.store.updateItemFields(response.data.fields);
            });
        
            // Set to false to trigger update default value
            this.store.updateState('isUpdatedNewProjects', false)
        },
        loadProject(){
            if(this.dhEditor) this.dhEditor.remove();

            // Take the first project to load 
            const projectId = this.store.ad.projects[0].project_id
            this.store.updateSelectedSize(this.store.ad.projects[0].project_id)

            this.$designHuddle.Editors.insert(this.editorContainerId, { 
                project_id: projectId
            }, function(err, e){
                this.dhEditor = e
                console.log("loadProject error", err)
                this.getProjectData()
                this.setUpHandleSelectionChange()
                this.setUpHandleProjectDataModification()
                this.setUpHandleUnauthenticated()
                this.setUpHandleEditorError()

                this.store.updateState('isLoading', { status: false, message: null, isShowSpinner: null });
            }.bind(this));
            
        },
        // Get elements of the displaying project
        getProjectData() {
            if(!this.dhEditor) return

            this.dhEditor.getProjectData({}, function(err, project){
                console.log("Project data:", project);
                this.store.updateEditorData(project)
                this.store.updateState('isEditorReady', true)

                if(this.store.states.isSwitchingSize) 
                {
                    this.handleSwitchingSize()
                }
            }.bind(this));
        },

        setUpHandleEditorError() {
            this.dhEditor.handleError(function(e){
                console.log("Editor error occurred:", e);
            });
        },

        async handleSwitchingSize() {
            if (!this.store.states.isSwitchingSize) return;
            const elementsNeedToBeUpdate = this.store.getDefaultSwitchSizeElementValuesInPUO
            await this.updateProjectElement(elementsNeedToBeUpdate);
        },

        checkFileAccessibility(imageUrl) {
            return new Promise((resolve) => {
                var http = new XMLHttpRequest();
                http.open('HEAD', imageUrl, false);
                http.send();
                
                console.log(`File accessibility check for ${imageUrl}: HTTP status = ${http.status}`);
                
                resolve(http.status === 200);
            });
        },

        handleItemAccessibilityCheck(callback) {
            return new Promise((resolve) => {
                const currentItemIndex = this.store.ad.currentItemIndex;
                const currentItem = this.store.ad.itemList[currentItemIndex];
        
                if (!currentItem) {
                    console.log("No more items to check");
                    resolve(null);
                    return;
                }
        
                if ('accessibility' in currentItem) {
                    if (currentItem.accessibility) {
                        resolve(callback(currentItem));
                    } else {
                        this.handleSwitchItem()
                        resolve();
                    }
                } else {
                    console.log("Item hasn't been checked yet, performing accessibility check");
                    const currentItemThumbnailUrl = currentItem.thumbnail_urls[0];
                    this.checkFileAccessibility(currentItemThumbnailUrl).then(isFileReachable => {
                        // Update the accessibility status in the store
                        this.store.updateCurrentItemAccessibility(isFileReachable);
        
                        if (isFileReachable) {
                            resolve(callback(currentItem));
                        } else {
                            this.handleSwitchItem()
                            resolve();
                        }
                    });
                }
            });
        },
        handleSwitchItem() {
            // Check conditions and switch to next item if necessary
            if (!this.store.getIsAllItemsChecked || (this.store.getIsAllItemsChecked && this.store.getHasAccessibleItem)) {
                console.log("Item is not accessible, moving to next item");
                this.store.switchToNextItem()
            }else{
                console.warn("Warning: Unable to switch to next item. All items have been checked, but no accessible items found.");
            }
        },
        updateProjectElement(elements) {
            console.log("updateProjectElement", elements)
            if(!this.dhEditor || isEmpty(elements)) return

            this.dhEditor.updateElements({
                elements: elements
            })

            // Reset when update element finished
            if(this.store.states.isSwitchingSize) this.store.updateState('isSwitchingSize', false)
            this.store.updateState('isLoading', false)
        },
        updateLogo() {
            const logoElements = this.store.getElementsByClassName('logo');
            if(!logoElements) {
                console.warn("The element with the class `logo` does not exist. Cannot update logo.");
                return
            }

            const formattedElements = this.store.formatElementsToProjectUpdateObject(logoElements, 'url',  this.store.ad.logo?.asset);
            this.updateProjectElement(formattedElements)
        },
        async updateItemImage() {
            if(!this.store.getStatesByName("isEditorReady")) return;
            const imageElements = this.store.getElementsByClassName('image');
        
            await this.handleItemAccessibilityCheck((item) => {
                const formattedElements = this.store.formatElementsToProjectUpdateObject(imageElements, 'url',  item.thumbnail_urls[0]);
                this.updateProjectElement(formattedElements);
                return item;
            });
        },
        updateItemDisplayDetails() {
            const formattedItemElements = this.store.getFormattedItemElements
            this.updateProjectElement(formattedItemElements)
        },
        setUpHandleSelectionChange() {
            if(!this.dhEditor) return
            this.dhEditor.handleSelectionChange(function(e, elements){
                console.log("Selected Element(s): ", elements);
                if(this.store.isLogoSelected(elements)) {
                    this.showBrandSection()
                }else{
                    this.hideBrandSection()
                }
            }.bind(this));
        },
        // Sync our store's project data with the actual editor's project data when the project data is updated in the DH editor.
        setUpHandleProjectDataModification(){
            if(!this.dhEditor) return
            this.dhEditor.handleProjectDataModification(function(e, updates){
                console.log("Pages/Scenes Altered:", Object.keys(updates.pages || updates.scenes).length);
                this.getProjectData()
            }.bind(this));
        },
        setUpHandleUnauthenticated() {
            if(!this.dhEditor) return
            this.dhEditor.handleUnauthenticated(function(e){
                this.$emit('forceRefreshAccessToken')
            }.bind(this));
        },
        switchProject(newProjectId) {
            this.store.states.isSwitchingSize = true
            this.store.updateState('isSwitchingSize', true)
            this.store.updateState('isEditorReady', false)
            this.dhEditor.changeProject(newProjectId);
            this.getProjectData()
        },
        showBrandSection() {
            this.store.updateState("isShowBrandSection", true)
        },
        hideBrandSection() {
            this.store.updateState("isShowBrandSection", false)
        },
        handleColourPaletteChanges() {
            if(!this.dhEditor) return
            if(!this.store.ad.logo) return
            if(!this.store.ad.logo.palette) return

            const storeColourPalette = this.store.getColourPalette
            const colourPaletteKeys = Object.keys(storeColourPalette);
            const logo = this.store.getLogo()
            colourPaletteKeys.forEach(key => {
              if (logo.palette[key] !== storeColourPalette[key]) {
                // Find elements matching this colour
                const elements = this.store.getElementsByClassName(key);
                if(!elements) 
                {
                    console.warn(`Color palette "${key}" changed, but no element with class "${key}" exists in the editor.`);
                    return
                }
                const formattedElements = this.store.formatElementsToProjectUpdateObject(elements, 'color', this.store.colourPalette[key]);
                
                if (elements.length > 0) {
                  // Update the colour of these elements
                  this.updateProjectElement(formattedElements);
                }
              }
            });
        },
        applyColourPaletteToEditor() {
            if(!this.dhEditor) return
            const elementsNeedToApplyColours = this.store.getElementsMatchingColourPalette
            this.updateProjectElement(elementsNeedToApplyColours)
        },
        refreshEditorToken(token) {
            if (!this.dhEditor) return
            this.dhEditor.refreshAccessToken(token);
        }
    },
    watch: {
        'store.ad.templates'() {
            // Create projects only when not in edit mode; however, allow project creation during template switching when there are existing projects (i.e., ad.projects.length is greater than 0).
            if (!(this.store.states.isEditMode && this.store.ad.projects.length === 0)) {
                this.createProjects();
            }
        },
        'store.ad.projects'() {
            if(this.store.ad.projects.length === this.store.ad.templates.length) {
                this.store.updateState('isCreateProjectDone', true)
                this.loadProject() // Only load project when all projects are created
            }
        },
        'store.ad.selectedSizeProjectId'(newValue) {
            this.switchProject(newValue)
        },
        'store.ad.logo': {
            handler: (function (newSelected) {
                if(newSelected && this.store.states.isCreateProjectDone) this.updateLogo()
            }),
            deep: true
        },
        'store.ad.currentItemIndex': {
            handler: debounce(function () {
                this.updateItemImage()
                this.updateItemDisplayDetails()
            }, 500),
            deep: true
        },
        'store.ad.itemList': {
            handler: (function () {
                this.updateItemImage()
                this.updateItemDisplayDetails()
            }),
            deep: true
        },
        'store.colourPalette': {
            handler: debounce(function () {
                this.handleColourPaletteChanges()
            }, 2000),
            deep: true
        },
        'store.dhAccessToken' (newValue) {
            this.refreshEditorToken(newValue)
        }
    },
    components: {
        LogoModal,
    },
    template:
    `
        <div class="dh-video-ad-preview row h-100 w-100 no-gutters">
            <div id="dh-container" class="w-100"></div>
        </div>
    `
    ,
}

export { DHVideoAdEditor };
