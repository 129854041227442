const SingleAdPreview = {
    name: "SingleAdPreview",
    props: {
        templateId: String,
        basePresetPreviewUrl: String,
        size: String,
        store: Object,
        base_preset_preview_url: String,
        isLoadingAd: Boolean,
    },

    data() {
        return {
            previewURL: '',
            secondPreviewURL: '',
            showingFirstIframe: true,
            clearOldIframeTimeoutFunction: null,
            timeout: 1000,
            isShowSpinner: false
        }
    },

    computed: {
        presetPreviewURL: function () {
            return this.templateId ? this.store.createPreviewURL(this.base_preset_preview_url, this.templateId, this.store.ad.theme_config.theme_version.id) : null
        }
    },

    mounted () {
        this.updatePreviewIframe(this.presetPreviewURL, '');
    },

    watch: {
        presetPreviewURL: function (newURL, oldURL) {
            // Use nextTick to manage the frequency of the updatePreviewIframe function calls. The use of debounce is not advisable in this instance, as it would result in only the last SingleAdPreview component updating the iframe.
            this.$nextTick(() => {
                this.updatePreviewIframe(newURL, oldURL);
            });
        },
    },

    methods: {
        updatePreviewIframe(newVal, oldVal) {
            if (!this.isLoadingAd) {
                this.$emit('updateIsLoadingAd', true);
                //trigger loading animation
                this.isShowSpinner = true
            }
            //}
            if (this.showingFirstIframe) {
                //if the first iframe is showing, assign newVal to secondPreviewURL
                this.updateIframeDisplay("secondPreviewURL", newVal, "previewURL");
            } else {
                //otherwise we will show first iframe by assigning newVal to previewURL
                this.updateIframeDisplay("previewURL", newVal, "secondPreviewURL");
            }
        },

        updateIframeDisplay(targetPreviewUrlName, newValForTarget, anotherPreviewUrlName) {
            //prevent multiple refresh before setTimeout done
            //if a timeout is running already, cancel the old one
            if (this.clearOldIframeTimeoutFunction) {
                clearTimeout(this.clearOldIframeTimeoutFunction);
                //reset varibale to null
                this.clearOldIframeTimeoutFunction = null;
            }

            this[targetPreviewUrlName] = newValForTarget;

            //delay update to have approximate wait for content to load and keep old preview
            this.clearOldIframeTimeoutFunction = setTimeout(function () {
                this.displayIframe();
                this.$emit('updateIsLoadingAd', false);
                this.isShowSpinner = false
                //reset timeout to null as finished timeout will still have value
                this.clearOldIframeTimeoutFunction = null;
                //make sure the new iframe is shown before clearing url to prevent few sec blank
                //setTimeout(()=>{
                //    this.clearPreviewUrlValue(anotherPreviewUrlName);
                //}, 10000);
            }.bind(this), this.timeout);
        },

        displayIframe() {
            this.showingFirstIframe = !this.showingFirstIframe;
        },

        clearPreviewUrlValue(previewUrlName) {
            this[previewUrlName] = "";
        },

        width(size) {
            return size.split('X')[0].trim();
        },

        height(size) {
            return size.split('X')[1].trim();
        }
    },

    template:
        `
        <div class="iframe-container" :style="'width: '+ width(size) + 'px; height: '+ height(size) + 'px'">      
            <iframe class="ad-iframe position-absolute"
                :class="showingFirstIframe ? 'show' : 'hide'"
                v-bind:width="width(size)"
                v-bind:height="height(size)"
                v-bind:src="previewURL"
                scrolling="no"
                frameborder="0">
                </iframe>
            <iframe class="ad-iframe position-absolute"
                :class="!showingFirstIframe ? 'show' : 'hide'"
                v-bind:width="width(size)"
                v-bind:height="height(size)"
                v-bind:src="secondPreviewURL"
                scrolling="no"
                frameborder="0">
                </iframe>
            <div  
            :class="isShowSpinner ? 'show' : 'hide'" 
            class="ad-overlay my-2 position-absolute d-flex align-items-center justify-content-center" 
            :style="'width: '+ width(size) + 'px; height: '+ height(size) + 'px'"
            >
                <div class="spinner-grow"></div>
            </div>
        </div>

    `
}

export { SingleAdPreview };
