import { StatusAlert } from "../common/components/StatusAlert.js"
import ApiService, {WebsitesService} from '../common/api.service.js'

let AdminTools = {
    name: "AdminTools",
    props: {
        enableTypes: Array,
        websiteName: String
    },
    data() {
        return {
            isFormSubmitting: false,
            isShowAlert: false,
            isSuccess: false,
            responseMsg: null,
            errorMsg: null, // For meta performance report only
            metaInterval: 'Lifetime',
            reportStartDate: new Date(),
            reportEndDate: new Date()
        }
    },
    methods: {
        runStrategicAdDelivery() {
            this.isFormSubmitting = true
            this.isShowAlert = false
            this.errorMsg = null

            ApiService.doRequest('post', `../admin_tools/${this.websiteName}/run-prepare`, {}).then(response => {
                this.onApiSuccess(response.message)
            }).catch(error => {
                this.onApiFailed()
            });
        },
        regenerateImages() {
            WebsitesService.requestRegenerateImages(this.websiteName).then(response => {
                this.onApiSuccess(response.detail)
            }).catch(error => {
                this.onApiFailed()
            });
        },
        onApiSuccess(message) {
            this.responseMsg = message
            this.isFormSubmitting = false
            this.isShowAlert = true
            this.isSuccess = true
        },
        onApiFailed(message = null) {
            this.isSuccess = false
            this.isFormSubmitting = false
            this.isShowAlert = true
            this.responseMsg = null
            if (message) this.errorMsg = message
        },
        formatDate(date) {
            console.log('the daye', date)
            return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');
        }
    },
    components: {
        StatusAlert
    },
    template: `
    <div class="admin-tools-container">
        <StatusAlert :isSuccess="isSuccess" :successMessage="responseMsg" :errorMessage="errorMsg" :isShowAlert="isShowAlert" />
        <h5>Admin Tools</h5>
        <div class="mb-3 border p-3 bg-light d-flex align-items-center justify-content-between default-theme-border-radius shadow">
            <h6 class="m-0">Strategic Ad Delivery</h6>
            <b-button :disabled="isFormSubmitting" variant="primary" class="" @click="runStrategicAdDelivery()">Run</b-button>
        </div>
        <div class="mb-3 border p-3 bg-light d-flex align-items-center justify-content-between default-theme-border-radius shadow">
            <h6 class="m-0">Image Cache</h6>
            <b-button :disabled="isFormSubmitting" variant="primary" class="" @click="regenerateImages()">Clear and Regenerate Images</b-button>
        </div>
    </div>
    `
}

export { AdminTools };
