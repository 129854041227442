let ItemsetCard = {
    props: {
        itemset: Object,
        default_image: String,
        preset_image_thumbnail: String,
        active: false,
        website: String,
        showDelete: false
    },
    data: function () {
        return ({
          setURL: `/${this.website}/categories/${this.itemset.id}/edit`,
        })
    },
    methods: {
        emitSelect: function () {
            this.$emit('select',
                {
                    id: this.itemset.id,
                    name: this.itemset.name,
                    type: this.itemset.type,
                    image_url: this.itemset.image_urls[0]? this.itemset.image_urls[0] : this.preset_image_thumbnail,
                    item_count: this.itemset.item_count,
                    item_filter: this.itemset.item_filter
                }
            )
        }
    },
    computed: {
        activeClass: function() {
            return this.currentSelection === this.itemset.id ? "active-selection" : ""
        },

        itemsetCount: function () {
            return this.itemset.item_count === 1 ? this.itemset.item_count + " item" : this.itemset.item_count + " items"
        },
        _showDelete: function () {
            return this.showDelete && this.itemset.type !== 'default'
        }
    },
    template: `
    <div>
  <b-card
    v-bind:img-src="itemset.image_urls.length > 0 ? itemset.image_urls[0] : default_image"
    img-alt="Image"
    img-top
    tag="article"
    class="mb-3 itemset-card cursor-pointer"
    :class="{'active-selection': active}"
  >
    <template>
    <a @click="emitSelect()" @click.middle="$emit('open', itemset.id)" class="stretched-link"> 
        <h5 class="itemset-card-title pseudolink">{{ itemset.name }}</h5>
      </a>
    </template>
  <div class="d-flex justify-content-between align-items-start">
    <b-card-text class="itemset-card-text">
      {{ itemsetCount }}
    </b-card-text>
    <font-awesome-icon v-if="_showDelete" icon="trash" class="secondary" @click="$emit('delete', itemset)" aria-hidden="true" style="z-index:  1"></font-awesome-icon>
    </div>
  </b-card>
</div>
    `

}

export {ItemsetCard}